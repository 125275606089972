import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Facebook, 
  Twitter, 
  Linkedin, 
  Instagram, 
  Mail, 
  Phone, 
  MapPin
} from 'lucide-react';
import Logo from './Logo';

export default function Footer() {
  const navigation = {
    solutions: [
      { name: 'Web Development', href: '/solutions#web' },
      { name: 'Digital Marketing', href: '/solutions#marketing' },
      { name: 'Mobile Development', href: '/solutions#mobile' },
      { name: 'Cloud Services', href: '/solutions#cloud' },
      { name: 'E-commerce', href: '/solutions#ecommerce' },
      { name: 'Custom Software', href: '/solutions#custom' },
    ],
    company: [
      { name: 'About Us', href: '/about' },
      { name: 'Our Work', href: '/work' },
      { name: 'Blog', href: '/blog' },
      { name: 'Careers', href: '/careers' },
      { name: 'Contact', href: '/contact' },
    ],
    resources: [
      { name: 'Documentation', href: '/docs' },
      { name: 'Support Center', href: '/support' },
      { name: 'FAQ', href: '/faq' },
      { name: 'Case Studies', href: '/case-studies' },
    ],
    legal: [
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'Terms of Service', href: '/terms' },
      { name: 'Cookie Policy', href: '/cookies' },
      { name: 'GDPR Compliance', href: '/gdpr' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://facebook.com/cyberedge',
        icon: Facebook,
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/cyberedge',
        icon: Twitter,
      },
      {
        name: 'LinkedIn',
        href: 'https://linkedin.com/company/cyberedge',
        icon: Linkedin,
      },
      {
        name: 'Instagram',
        href: 'https://instagram.com/cyberedge',
        icon: Instagram,
      },
    ],
  };

  const contactInfo = [
    {
      icon: Phone,
      label: 'Call Us',
      text: '+91 6301644590',
      subtext: 'Mon-Fri 9am-6pm IST',
      href: 'tel:+916301644590',
    },
    {
      icon: Mail,
      label: 'Email Us',
      text: 'info@cyberedgesolutions.com',
      subtext: '24/7 Support Available',
      href: 'mailto:info@cyberedgesolutions.com',
    },
    {
      icon: MapPin,
      label: 'Visit Us',
      text: 'PJR Arcade, Kukatpally',
      subtext: 'Hyderabad, Telangana 500085',
      href: 'https://maps.google.com/?q=PJR+Arcade+Kukatpally+Hyderabad',
    },
  ];

  return (
    <footer className="bg-gradient-to-r from-blue-600 to-blue-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      
      {/* Contact Bar */}
      <div className="bg-blue-700 bg-opacity-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {contactInfo.map((item, index) => (
              <a
                key={index}
                href={item.href}
                target={item.icon === MapPin ? '_blank' : undefined}
                rel={item.icon === MapPin ? 'noopener noreferrer' : undefined}
                className="flex items-center space-x-4 text-white hover:text-blue-100 transition-colors"
              >
                <div className="flex-shrink-0">
                  <div className="p-3 bg-blue-600 bg-opacity-50 rounded-lg">
                    <item.icon className="h-6 w-6" />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-blue-200">{item.label}</div>
                  <div className="font-medium">{item.text}</div>
                  <div className="text-sm text-blue-200">{item.subtext}</div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-16">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8 xl:gap-12">
          {/* Brand Column */}
          <div className="col-span-2">
            <div className="flex items-center space-x-3 mb-6">
              <Logo className="h-10 w-10" />
              <span className="text-2xl font-bold text-white">CyberEdge</span>
            </div>
            <p className="text-blue-100 mb-6 max-w-md">
              Empowering businesses with innovative digital solutions that drive growth and create lasting value in an ever-evolving digital landscape.
            </p>
            <div className="flex space-x-4">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-200 hover:text-white transition-colors"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>

          {/* Navigation Columns */}
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">
              Solutions
            </h3>
            <ul className="space-y-3">
              {navigation.solutions.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-blue-100 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">
              Company
            </h3>
            <ul className="space-y-3">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-blue-100 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">
              Resources
            </h3>
            <ul className="space-y-3">
              {navigation.resources.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-blue-100 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mt-8 mb-4">
              Legal
            </h3>
            <ul className="space-y-3">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-blue-100 hover:text-white transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-blue-500 mt-8 pt-8">
          <p className="text-blue-200 text-sm text-center">
            &copy; {new Date().getFullYear()} CyberEdge Solutions. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}