import React from 'react';

interface VectorProps {
  type: 'wave' | 'dots' | 'grid' | 'circles';
  className?: string;
}

export default function Vector({ type, className = '' }: VectorProps) {
  const patterns = {
    wave: (
      <svg className={className} viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
        <path 
          fill="currentColor"
          fillOpacity="0.1"
          d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        />
      </svg>
    ),
    dots: (
      <svg className={className} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="currentColor" fillOpacity="0.1" />
      </svg>
    ),
    grid: (
      <svg className={className} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path 
          fill="none"
          stroke="currentColor"
          strokeOpacity="0.1"
          d="M0,0 L20,0 M0,10 L20,10 M10,0 L10,20"
        />
      </svg>
    ),
    circles: (
      <svg className={className} width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="40" fill="none" stroke="currentColor" strokeOpacity="0.1" />
        <circle cx="50" cy="50" r="30" fill="none" stroke="currentColor" strokeOpacity="0.1" />
        <circle cx="50" cy="50" r="20" fill="none" stroke="currentColor" strokeOpacity="0.1" />
      </svg>
    )
  };

  return patterns[type];
}