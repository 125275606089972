import { create } from 'zustand';
import { User } from '../types';
import { supabase } from '../lib/supabase';
import { AuthError, AuthApiError } from '@supabase/supabase-js';

interface AuthState {
  user: User | null;
  loading: boolean;
  logs: string[];
  setUser: (user: User | null) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, businessDetails: any) => Promise<void>;
  signOut: () => Promise<void>;
  initializeAuth: () => Promise<void>;
  clearLogs: () => void;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const MAX_LOGS = 100;

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const addLog = (message: string, data?: any) => {
  const timestamp = new Date().toISOString();
  return `[${timestamp}] ${message}${data ? '\n' + JSON.stringify(data, null, 2) : ''}`;
};

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: true,
  logs: [],
  setUser: (user) => set({ user }),
  clearLogs: () => set({ logs: [] }),

  initializeAuth: async () => {
    const log = (message: string, data?: any) => set(state => ({
      logs: [addLog(message, data), ...state.logs].slice(0, MAX_LOGS)
    }));

    try {
      log('Starting auth initialization');
      
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();

      if (sessionError) {
        log('Session error', sessionError);
        set({ user: null, loading: false });
        return;
      }

      if (!session?.user) {
        log('No active session');
        set({ user: null, loading: false });
        return;
      }

      log('Session found', { userId: session.user.id });

      // Wait for profile to be available
      let profile = null;
      for (let i = 0; i < MAX_RETRIES; i++) {
        try {
          const { data, error } = await supabase
            .from('profiles')
            .select('role, subscription_status')
            .eq('user_id', session.user.id)
            .single();

          if (!error && data) {
            profile = data;
            break;
          }

          if (i < MAX_RETRIES - 1) {
            await sleep(RETRY_DELAY * Math.pow(2, i));
          }
        } catch (error) {
          log('Profile fetch attempt failed', { attempt: i + 1, error });
          
          if (i < MAX_RETRIES - 1) {
            await sleep(RETRY_DELAY * Math.pow(2, i));
          }
        }
      }

      if (!profile) {
        log('Profile not found');
        await supabase.auth.signOut();
        set({ user: null, loading: false });
        return;
      }

      set({
        user: {
          id: session.user.id,
          email: session.user.email!,
          role: profile.role,
          subscription_status: profile.subscription_status
        },
        loading: false
      });

      log('Auth initialization completed');
    } catch (error) {
      log('Auth initialization failed', error);
      set({ user: null, loading: false });
    }
  },

  signIn: async (email, password) => {
    const log = (message: string, data?: any) => set(state => ({
      logs: [addLog(message, data), ...state.logs].slice(0, MAX_LOGS)
    }));

    try {
      log('Starting sign in', { email });
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) {
        if (error instanceof AuthApiError && error.status === 400) {
          throw new Error('Invalid email or password');
        }
        throw error;
      }

      if (!data.user) throw new Error('No user returned from auth service');

      log('Sign in successful', { userId: data.user.id });

      // Wait for profile
      await sleep(1000);

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('role, subscription_status')
        .eq('user_id', data.user.id)
        .single();

      if (profileError || !profile) {
        throw new Error('Unable to fetch user profile');
      }

      set({
        user: {
          id: data.user.id,
          email: data.user.email!,
          role: profile.role,
          subscription_status: profile.subscription_status
        }
      });

      log('Sign in completed');
    } catch (error) {
      log('Sign in failed', error);
      if (error instanceof AuthApiError && error.status === 400) {
        throw new Error('Invalid email or password');
      }
      throw new Error('Service temporarily unavailable. Please try again later.');
    }
  },

  signUp: async (email, password, businessDetails) => {
    const log = (message: string, data?: any) => set(state => ({
      logs: [addLog(message, data), ...state.logs].slice(0, MAX_LOGS)
    }));

    try {
      log('Starting sign up', { email });
      await supabase.auth.signOut();

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            role: 'client'
          }
        }
      });

      if (error) {
        if (error instanceof AuthApiError && error.status === 400) {
          if (error.message.toLowerCase().includes('already exists')) {
            throw new Error('An account with this email already exists');
          }
        }
        throw error;
      }

      if (!data.user) throw new Error('No user returned from auth service');

      log('User created', { userId: data.user.id });

      // Wait for profile creation
      await sleep(2000);

      // Create client record
      const { error: clientError } = await supabase
        .from('clients')
        .insert([{
          user_id: data.user.id,
          company_name: businessDetails.companyName,
          email: email,
          phone: businessDetails.phone || null
        }]);

      if (clientError) throw clientError;

      log('Sign up completed successfully');
    } catch (error) {
      log('Sign up failed', error);
      throw error;
    }
  },

  signOut: async () => {
    const log = (message: string, data?: any) => set(state => ({
      logs: [addLog(message, data), ...state.logs].slice(0, MAX_LOGS)
    }));

    try {
      log('Starting sign out');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      log('Sign out successful');
      set({ user: null });
    } catch (error) {
      log('Sign out failed', error);
      set({ user: null });
      throw error;
    }
  }
}));

// Auth state listener
supabase.auth.onAuthStateChange(async (event, session) => {
  const log = (message: string, data?: any) => useAuthStore.setState(state => ({
    logs: [addLog(message, data), ...state.logs].slice(0, MAX_LOGS)
  }));

  log('Auth state changed', { event, userId: session?.user?.id });

  if (event === 'SIGNED_IN' && session?.user) {
    try {
      await sleep(1000);

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('role, subscription_status')
        .eq('user_id', session.user.id)
        .single();

      if (profileError || !profile) {
        log('Profile fetch error', profileError);
        await supabase.auth.signOut();
        useAuthStore.setState({ user: null });
        return;
      }

      useAuthStore.setState({
        user: {
          id: session.user.id,
          email: session.user.email!,
          role: profile.role,
          subscription_status: profile.subscription_status
        }
      });
    } catch (error) {
      log('Auth state change error', error);
      await supabase.auth.signOut();
      useAuthStore.setState({ user: null });
    }
  } else if (event === 'SIGNED_OUT') {
    log('User signed out');
    useAuthStore.setState({ user: null });
  }
});