import React from 'react';
import { Link } from 'react-router-dom';
import { 
  LifeBuoy, 
  MessageCircle, 
  FileText, 
  Book, 
  ArrowRight,
  Phone,
  Mail,
  Clock
} from 'lucide-react';

export default function Support() {
  const supportChannels = [
    {
      icon: <MessageCircle className="h-6 w-6 text-blue-500" />,
      title: 'Live Chat',
      description: 'Get instant help from our support team',
      action: 'Start Chat',
      href: '#chat'
    },
    {
      icon: <Phone className="h-6 w-6 text-blue-500" />,
      title: 'Phone Support',
      description: 'Speak directly with our experts',
      action: 'Call Now',
      href: 'tel:+916301644590'
    },
    {
      icon: <Mail className="h-6 w-6 text-blue-500" />,
      title: 'Email Support',
      description: 'Send us your detailed inquiries',
      action: 'Send Email',
      href: 'mailto:support@cyberedge.com'
    }
  ];

  const resources = [
    {
      icon: <FileText className="h-6 w-6 text-blue-500" />,
      title: 'Knowledge Base',
      description: 'Find answers to common questions',
      href: '/docs'
    },
    {
      icon: <Book className="h-6 w-6 text-blue-500" />,
      title: 'Documentation',
      description: 'Detailed guides and tutorials',
      href: '/documentation'
    },
    {
      icon: <LifeBuoy className="h-6 w-6 text-blue-500" />,
      title: 'FAQ',
      description: 'Frequently asked questions',
      href: '/faq'
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <LifeBuoy className="h-16 w-16 text-white mx-auto mb-6" />
            <h1 className="text-4xl font-bold text-white mb-6">
              How Can We Help?
            </h1>
            <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Get the support you need, when you need it
            </p>
          </div>
        </div>
      </div>

      {/* Support Channels */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Contact Support</h2>
            <p className="text-xl text-gray-600">
              Choose your preferred way to get in touch
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {supportChannels.map((channel, index) => (
              <a
                key={index}
                href={channel.href}
                className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 border border-gray-200 group"
              >
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-blue-50 rounded-lg mr-4 group-hover:bg-blue-100 transition-colors">
                    {channel.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">
                    {channel.title}
                  </h3>
                </div>
                <p className="text-gray-600 mb-4">{channel.description}</p>
                <div className="flex items-center text-blue-600 group-hover:text-blue-700">
                  {channel.action}
                  <ArrowRight className="ml-2 h-4 w-4" />
                </div>
              </a>
            ))}
          </div>

          {/* Support Hours */}
          <div className="bg-gray-50 rounded-lg p-8 mb-16">
            <div className="flex items-center justify-center mb-6">
              <Clock className="h-8 w-8 text-blue-500 mr-4" />
              <h2 className="text-2xl font-bold text-gray-900">Support Hours</h2>
            </div>
            <div className="max-w-2xl mx-auto text-center">
              <p className="text-lg text-gray-600 mb-4">
                Our support team is available during the following hours:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <h3 className="font-semibold text-gray-900 mb-2">Weekdays</h3>
                  <p className="text-gray-600">9:00 AM - 6:00 PM IST</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <h3 className="font-semibold text-gray-900 mb-2">Weekends</h3>
                  <p className="text-gray-600">10:00 AM - 4:00 PM IST</p>
                </div>
              </div>
            </div>
          </div>

          {/* Self-Help Resources */}
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Self-Help Resources</h2>
            <p className="text-xl text-gray-600">
              Find quick answers in our comprehensive resources
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {resources.map((resource, index) => (
              <Link
                key={index}
                to={resource.href}
                className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 border border-gray-200 group"
              >
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-blue-50 rounded-lg mr-4 group-hover:bg-blue-100 transition-colors">
                    {resource.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">
                    {resource.title}
                  </h3>
                </div>
                <p className="text-gray-600 mb-4">{resource.description}</p>
                <div className="flex items-center text-blue-600 group-hover:text-blue-700">
                  Learn More
                  <ArrowRight className="ml-2 h-4 w-4" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}