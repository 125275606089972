import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Rocket, 
  BarChart, 
  Globe, 
  Users, 
  Shield, 
  Award,
  ArrowRight,
  Building2,
  Stethoscope,
  GraduationCap,
  ShoppingBag,
  Factory,
  Landmark,
  Truck,
  Hotel,
  Briefcase,
  Star,
  TrendingUp,
  CheckCircle2,
  Trophy,
  Target,
  Zap
} from 'lucide-react';
import Logo from '../components/Logo';
import Vector from '../components/Vector';
import GrowthChart from '../components/charts/GrowthChart';
import RadialChart from '../components/charts/RadialChart';
import ComparisonChart from '../components/charts/ComparisonChart';

export default function Home() {
  const growthData = [
    { name: '2019', value: 30 },
    { name: '2020', value: 45 },
    { name: '2021', value: 65 },
    { name: '2022', value: 85 },
    { name: '2023', value: 100 },
    { name: '2024', value: 120 }
  ];

  const performanceData = [
    { name: 'Website Speed', value: 90, fill: '#2563eb' },
    { name: 'User Satisfaction', value: 95, fill: '#16a34a' },
    { name: 'Project Delivery', value: 88, fill: '#ea580c' },
    { name: 'Support Response', value: 92, fill: '#8b5cf6' }
  ];

  const comparisonData = [
    { name: 'Traffic', before: 1000, after: 5000 },
    { name: 'Conversion', before: 2, after: 8 },
    { name: 'Revenue', before: 5000, after: 25000 },
    { name: 'Engagement', before: 10, after: 45 }
  ];

  const services = [
    {
      icon: <Globe className="h-8 w-8 text-blue-500" />,
      title: 'Digital Solutions',
      description: 'Comprehensive digital solutions tailored to your business needs',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <BarChart className="h-8 w-8 text-blue-500" />,
      title: 'Growth Strategy',
      description: 'Data-driven strategies to accelerate your business growth',
      image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80&w=2070'
    },
    {
      icon: <Users className="h-8 w-8 text-blue-500" />,
      title: 'Expert Support',
      description: '24/7 dedicated support from our team of experts',
      image: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80&w=2070'
    }
  ];

  const industries = [
    {
      icon: <Building2 className="h-12 w-12 text-blue-500" />,
      title: 'Real Estate',
      description: 'Digital solutions for property management and real estate operations',
      image: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&q=80&w=800'
    },
    {
      icon: <Stethoscope className="h-12 w-12 text-blue-500" />,
      title: 'Healthcare',
      description: 'Advanced systems for healthcare providers and medical facilities',
      image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&q=80&w=800'
    },
    {
      icon: <GraduationCap className="h-12 w-12 text-blue-500" />,
      title: 'Education',
      description: 'E-learning platforms and educational management systems',
      image: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?auto=format&fit=crop&q=80&w=800'
    }
  ];

  const features = [
    {
      icon: <Shield className="h-6 w-6 text-blue-500" />,
      title: 'Enterprise Security',
      description: 'Bank-grade security for your digital assets'
    },
    {
      icon: <Award className="h-6 w-6 text-blue-500" />,
      title: 'Award-winning Service',
      description: 'Recognized excellence in digital solutions'
    },
    {
      icon: <Rocket className="h-6 w-6 text-blue-500" />,
      title: 'Fast Implementation',
      description: 'Quick deployment of your digital solutions'
    }
  ];

  const stats = [
    {
      icon: <TrendingUp className="h-6 w-6 text-blue-500" />,
      number: "500+",
      label: "Projects Completed",
      description: "Successfully delivered digital solutions"
    },
    {
      icon: <Users className="h-6 w-6 text-blue-500" />,
      number: "98%",
      label: "Client Satisfaction",
      description: "Based on client feedback and reviews"
    },
    {
      icon: <Trophy className="h-6 w-6 text-blue-500" />,
      number: "50+",
      label: "Industry Awards",
      description: "Recognition for excellence"
    },
    {
      icon: <Star className="h-6 w-6 text-blue-500" />,
      number: "15+",
      label: "Years Experience",
      description: "Delivering digital excellence"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section with Animated Background */}
      <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800">
          <Vector type="dots" className="absolute inset-0 w-full h-full text-white" />
        </div>
        <div className="absolute inset-0">
          <Vector type="wave" className="absolute bottom-0 w-full text-white transform translate-y-1/2" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 text-center">
          <div className="animate-float mb-8">
            <Logo className="h-32 w-32 mx-auto" />
          </div>
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
            Welcome to CyberEdge
          </h1>
          <p className="text-xl md:text-2xl text-blue-100 mb-12 max-w-3xl mx-auto">
            Elevating Your Digital Presence to New Heights
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link
              to="/register"
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg text-blue-700 bg-white hover:bg-blue-50 transition-all duration-300 transform hover:-translate-y-1"
            >
              Get Started
              <ArrowRight className="ml-2 h-6 w-6" />
            </Link>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-lg text-white hover:bg-white hover:text-blue-700 transition-all duration-300 transform hover:-translate-y-1"
            >
              Contact Sales
            </Link>
          </div>
        </div>
      </div>

      {/* Growth Charts Section */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Growth & Performance
            </h2>
            <p className="text-xl text-gray-600">
              Delivering measurable results and continuous improvement
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <GrowthChart 
                data={growthData} 
                title="Business Growth" 
                color="#2563eb" 
              />
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <RadialChart 
                data={performanceData} 
                title="Performance Metrics" 
              />
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <ComparisonChart 
                data={comparisonData} 
                title="Client Success Metrics" 
              />
            </div>
          </div>
        </div>
      </div>

      {/* Services Section with Parallax */}
      <div className="relative py-20 overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.1)'
          }}
        />
        <Vector type="grid" className="absolute inset-0 w-full h-full text-white" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">
              Our Services
            </h2>
            <p className="text-xl text-blue-100">
              Comprehensive solutions for your digital success
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="group relative overflow-hidden rounded-xl bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all duration-500"
              >
                <div className="absolute inset-0">
                  <img 
                    src={service.image} 
                    alt={service.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                </div>
                <div className="relative p-8">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 backdrop-blur-sm rounded-lg group-hover:bg-white/20 transition-colors duration-500">
                      {service.icon}
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold text-white text-center mb-4">
                    {service.title}
                  </h3>
                  <p className="text-blue-100 text-center">
                    {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Industries Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Industries We Serve
            </h2>
            <p className="text-xl text-gray-600">
              Tailored solutions for diverse business sectors
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {industries.map((industry, index) => (
              <div
                key={index}
                className="group relative overflow-hidden rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute inset-0">
                  <img 
                    src={industry.image} 
                    alt={industry.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                </div>
                <div className="relative p-8">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 backdrop-blur-sm rounded-lg">
                      {industry.icon}
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold text-white text-center mb-4">
                    {industry.title}
                  </h3>
                  <p className="text-blue-100 text-center">
                    {industry.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section with Vector Background */}
      <div className="relative py-20 bg-gradient-to-r from-blue-600 to-blue-800 overflow-hidden">
        <Vector type="circles" className="absolute inset-0 w-full h-full text-white" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div
                key={index}
                className="bg-white/10 backdrop-blur-sm p-8 rounded-xl hover:bg-white/20 transition-all duration-300"
              >
                <div className="flex justify-center mb-4">
                  <div className="p-3 bg-white/10 rounded-lg">
                    {stat.icon}
                  </div>
                </div>
                <div className="text-4xl font-bold text-white text-center mb-2">
                  {stat.number}
                </div>
                <div className="text-lg font-semibold text-blue-100 text-center mb-2">
                  {stat.label}
                </div>
                <p className="text-blue-100 text-center">
                  {stat.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative py-20 overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.3)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-blue-800/50" />
        <Vector type="wave" className="absolute bottom-0 w-full text-white transform translate-y-1/2" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-white mb-4">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Join thousands of businesses that trust CyberEdge with their digital presence
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to="/register"
                className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg text-blue-700 bg-white hover:bg-blue-50 transition-all duration-300 transform hover:-translate-y-1"
              >
                Start Your Journey
                <ArrowRight className="ml-2 h-6 w-6" />
              </Link>
              <Link
                to="/contact"
                className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-lg text-white hover:bg-white hover:text-blue-700 transition-all duration-300 transform hover:-translate-y-1"
              >
                Schedule a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}