import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface ComparisonChartProps {
  data: Array<{
    name: string;
    before: number;
    after: number;
  }>;
  title?: string;
}

export default function ComparisonChart({ data, title }: ComparisonChartProps) {
  return (
    <div className="w-full h-[300px]">
      {title && (
        <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="before" fill="#94a3b8" name="Before" />
          <Bar dataKey="after" fill="#2563eb" name="After" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}