import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseKey) {
  console.warn('Missing Supabase environment variables. Please check your .env file.');
  throw new Error('Missing Supabase configuration');
}

// Create Supabase client
export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: localStorage,
    storageKey: 'cyberedge.auth.token',
    flowType: 'pkce'
  },
  global: {
    headers: {
      'x-client-info': 'cyberedge-web'
    }
  },
  db: {
    schema: 'public'
  }
});

// Initialize health check with retry mechanism
const checkSupabaseHealth = async (retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      // Simple ping query that doesn't require authentication
      const { data, error } = await supabase
        .from('profiles')
        .select('count')
        .limit(1)
        .single();

      if (!error) {
        console.log('Supabase connection established');
        return true;
      }

      console.warn(`Health check attempt ${i + 1} failed:`, error);
      
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      }
    } catch (error) {
      console.warn(`Health check attempt ${i + 1} failed:`, error);
      
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      }
    }
  }

  console.error('Failed to establish Supabase connection after retries');
  return false;
};

// Run initial health check in the background
checkSupabaseHealth().catch(console.error);

export default supabase;