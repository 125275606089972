import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CircuitBoard, ArrowRight, ArrowLeft } from 'lucide-react';
import { useAuthStore } from '../store/authStore';

type Step = 'account' | 'business' | 'marketing' | 'goals';

interface BusinessDetails {
  companyName: string;
  industry: string;
  size: string;
  website: string;
  phone: string;
}

interface MarketingNeeds {
  currentMarketing: string[];
  budget: string;
  timeline: string;
  priorities: string[];
}

interface Goals {
  objectives: string[];
  challenges: string;
  expectations: string;
}

export default function Register() {
  const [currentStep, setCurrentStep] = useState<Step>('account');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { signUp } = useAuthStore();

  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>({
    companyName: '',
    industry: '',
    size: '',
    website: '',
    phone: ''
  });

  const [marketingNeeds, setMarketingNeeds] = useState<MarketingNeeds>({
    currentMarketing: [],
    budget: '',
    timeline: '',
    priorities: []
  });

  const [goals, setGoals] = useState<Goals>({
    objectives: [],
    challenges: '',
    expectations: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (currentStep !== 'goals') {
      nextStep();
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      await signUp(email, password, {
        ...businessDetails,
        marketingNeeds,
        goals
      });
      
      navigate('/login', { 
        state: { 
          message: 'Account created successfully! Please sign in to continue.' 
        }
      });
    } catch (err: any) {
      setError(err.message || 'Failed to create account. Please try again.');
      setCurrentStep('account');
    } finally {
      setIsLoading(false);
    }
  };

  const industries = [
    'E-commerce',
    'Technology',
    'Healthcare',
    'Education',
    'Finance',
    'Manufacturing',
    'Retail',
    'Other'
  ];

  const companySizes = [
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '500+ employees'
  ];

  const marketingChannels = [
    'Social Media',
    'Email Marketing',
    'Content Marketing',
    'SEO',
    'PPC Advertising',
    'Traditional Marketing',
    'None'
  ];

  const budgetRanges = [
    'Under $1,000/month',
    '$1,000 - $5,000/month',
    '$5,000 - $10,000/month',
    '$10,000+/month',
    'Not sure yet'
  ];

  const timelines = [
    'Immediately',
    'Within 1 month',
    'Within 3 months',
    'Within 6 months',
    'Not sure yet'
  ];

  const businessObjectives = [
    'Increase brand awareness',
    'Generate more leads',
    'Improve online presence',
    'Increase sales',
    'Enter new markets',
    'Improve customer engagement',
    'Enhance digital infrastructure'
  ];

  const validateWebsiteUrl = (url: string): boolean => {
    if (!url) return true; // Empty URL is valid (optional field)
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const validateStep = () => {
    setError('');

    switch (currentStep) {
      case 'account':
        if (!email) return 'Email is required';
        if (!email.includes('@')) return 'Invalid email address';
        if (!password) return 'Password is required';
        if (password.length < 6) return 'Password must be at least 6 characters';
        break;
      case 'business':
        if (!businessDetails.companyName) return 'Company name is required';
        if (!businessDetails.industry) return 'Industry is required';
        if (!businessDetails.size) return 'Company size is required';
        if (businessDetails.website && !validateWebsiteUrl(businessDetails.website)) {
          return 'Please enter a valid website URL (e.g., https://example.com) or leave it empty';
        }
        break;
      case 'marketing':
        if (!marketingNeeds.budget) return 'Budget range is required';
        if (!marketingNeeds.timeline) return 'Timeline is required';
        break;
      case 'goals':
        if (goals.objectives.length === 0) return 'Please select at least one business objective';
        if (!goals.challenges) return 'Please describe your challenges';
        break;
    }
    return null;
  };

  const nextStep = () => {
    const error = validateStep();
    if (error) {
      setError(error);
      return;
    }

    const nextIndex = steps.findIndex(step => step.id === currentStep) + 1;
    if (nextIndex < steps.length) {
      setCurrentStep(steps[nextIndex].id as Step);
    }
  };

  const prevStep = () => {
    const prevIndex = steps.findIndex(step => step.id === currentStep) - 1;
    if (prevIndex >= 0) {
      setCurrentStep(steps[prevIndex].id as Step);
    }
  };

  const steps = [
    { id: 'account', name: 'Account' },
    { id: 'business', name: 'Business Details' },
    { id: 'marketing', name: 'Marketing Needs' },
    { id: 'goals', name: 'Goals & Expectations' }
  ];

  const renderStep = () => {
    switch (currentStep) {
      case 'account':
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="••••••••"
              />
              <p className="mt-1 text-sm text-gray-500">
                Must be at least 6 characters
              </p>
            </div>
          </div>
        );

      case 'business':
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                Company Name
              </label>
              <input
                id="companyName"
                value={businessDetails.companyName}
                onChange={(e) => setBusinessDetails({ ...businessDetails, companyName: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Company Name"
                required
              />
            </div>
            <div>
              <label htmlFor="industry" className="block text-sm font-medium text-gray-700">
                Industry
              </label>
              <select
                id="industry"
                value={businessDetails.industry}
                onChange={(e) => setBusinessDetails({ ...businessDetails, industry: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Industry</option>
                {industries.map((industry) => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                Company Size
              </label>
              <select
                id="size"
                value={businessDetails.size}
                onChange={(e) => setBusinessDetails({ ...businessDetails, size: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Company Size</option>
                {companySizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                value={businessDetails.phone}
                onChange={(e) => setBusinessDetails({ ...businessDetails, phone: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="+1 (555) 123-4567"
              />
            </div>
            <div>
              <label htmlFor="website" className="block text-sm font-medium text-gray-700">
                Website (optional)
              </label>
              <input
                id="website"
                type="url"
                value={businessDetails.website}
                onChange={(e) => setBusinessDetails({ ...businessDetails, website: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://www.example.com"
              />
              <p className="mt-1 text-sm text-gray-500">
                Include https:// if entering a website
              </p>
            </div>
          </div>
        );

      case 'marketing':
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Current Marketing Channels
              </label>
              <div className="grid grid-cols-2 gap-2">
                {marketingChannels.map((channel) => (
                  <label key={channel} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={marketingNeeds.currentMarketing.includes(channel)}
                      onChange={(e) => {
                        const updatedChannels = e.target.checked
                          ? [...marketingNeeds.currentMarketing, channel]
                          : marketingNeeds.currentMarketing.filter((c) => c !== channel);
                        setMarketingNeeds({ ...marketingNeeds, currentMarketing: updatedChannels });
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span>{channel}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
                Marketing Budget
              </label>
              <select
                id="budget"
                value={marketingNeeds.budget}
                onChange={(e) => setMarketingNeeds({ ...marketingNeeds, budget: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Budget Range</option>
                {budgetRanges.map((range) => (
                  <option key={range} value={range}>
                    {range}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="timeline" className="block text-sm font-medium text-gray-700">
                Implementation Timeline
              </label>
              <select
                id="timeline"
                value={marketingNeeds.timeline}
                onChange={(e) => setMarketingNeeds({ ...marketingNeeds, timeline: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Timeline</option>
                {timelines.map((timeline) => (
                  <option key={timeline} value={timeline}>
                    {timeline}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );

      case 'goals':
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Business Objectives
              </label>
              <div className="grid grid-cols-2 gap-2">
                {businessObjectives.map((objective) => (
                  <label key={objective} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={goals.objectives.includes(objective)}
                      onChange={(e) => {
                        const updatedObjectives = e.target.checked
                          ? [...goals.objectives, objective]
                          : goals.objectives.filter((o) => o !== objective);
                        setGoals({ ...goals, objectives: updatedObjectives });
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span>{objective}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label htmlFor="challenges" className="block text-sm font-medium text-gray-700">
                Current Challenges
              </label>
              <textarea
                id="challenges"
                value={goals.challenges}
                onChange={(e) => setGoals({ ...goals, challenges: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                rows={3}
                placeholder="What are your main business challenges?"
                required
              />
            </div>
            <div>
              <label htmlFor="expectations" className="block text-sm font-medium text-gray-700">
                Success Expectations
              </label>
              <textarea
                id="expectations"
                value={goals.expectations}
                onChange={(e) => setGoals({ ...goals, expectations: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                rows={3}
                placeholder="What does success look like for your business?"
                required
              />
            </div>
          </div>
        );
    }
  };

  const currentStepIndex = steps.findIndex((step) => step.id === currentStep);

  return (
    <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <CircuitBoard className="mx-auto h-12 w-12 text-blue-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Step {currentStepIndex + 1} of {steps.length}: {steps[currentStepIndex].name}
          </p>
          <p className="mt-2 text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
              Sign in
            </Link>
          </p>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${((currentStepIndex + 1) / steps.length) * 100}%` }}
          ></div>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
              {error}
            </div>
          )}

          {renderStep()}

          <div className="flex justify-between">
            {currentStepIndex > 0 && (
              <button
                type="button"
                onClick={prevStep}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Back
              </button>
            )}
            <button
              type="submit"
              disabled={isLoading}
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
                isLoading
                  ? 'bg-blue-400 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              } ml-auto`}
            >
              {isLoading ? (
                'Creating Account...'
              ) : currentStepIndex === steps.length - 1 ? (
                <>
                  Create Account
                  <ArrowRight className="h-4 w-4 ml-2" />
                </>
              ) : (
                <>
                  Next
                  <ArrowRight className="h-4 w-4 ml-2" />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}