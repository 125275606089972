import React from 'react';
import { Book, Code, Terminal, Database, Shield, Cloud } from 'lucide-react';

export default function Documentation() {
  const sections = [
    {
      icon: <Code className="h-6 w-6 text-blue-500" />,
      title: 'Getting Started',
      description: 'Quick start guides and basic concepts',
      links: [
        { title: 'Installation Guide', href: '#installation' },
        { title: 'Basic Concepts', href: '#concepts' },
        { title: 'First Steps', href: '#first-steps' }
      ]
    },
    {
      icon: <Terminal className="h-6 w-6 text-blue-500" />,
      title: 'API Reference',
      description: 'Complete API documentation and examples',
      links: [
        { title: 'REST API', href: '#rest-api' },
        { title: 'GraphQL API', href: '#graphql' },
        { title: 'WebSocket API', href: '#websocket' }
      ]
    },
    {
      icon: <Database className="h-6 w-6 text-blue-500" />,
      title: 'Data Management',
      description: 'Learn about data handling and storage',
      links: [
        { title: 'Data Models', href: '#models' },
        { title: 'Migrations', href: '#migrations' },
        { title: 'Backups', href: '#backups' }
      ]
    },
    {
      icon: <Shield className="h-6 w-6 text-blue-500" />,
      title: 'Security',
      description: 'Security best practices and guidelines',
      links: [
        { title: 'Authentication', href: '#auth' },
        { title: 'Authorization', href: '#authorization' },
        { title: 'Data Protection', href: '#protection' }
      ]
    },
    {
      icon: <Cloud className="h-6 w-6 text-blue-500" />,
      title: 'Deployment',
      description: 'Deployment guides and configuration',
      links: [
        { title: 'Cloud Setup', href: '#cloud' },
        { title: 'Configuration', href: '#config' },
        { title: 'Monitoring', href: '#monitoring' }
      ]
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <Book className="h-16 w-16 text-white mx-auto mb-6" />
            <h1 className="text-4xl font-bold text-white mb-6">
              Documentation
            </h1>
            <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Everything you need to know about our platform and services
            </p>
          </div>
        </div>
      </div>

      {/* Documentation Sections */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {sections.map((section, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow border border-gray-200"
              >
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-blue-50 rounded-lg mr-4">
                    {section.icon}
                  </div>
                  <h2 className="text-xl font-semibold text-gray-900">
                    {section.title}
                  </h2>
                </div>
                <p className="text-gray-600 mb-4">{section.description}</p>
                <ul className="space-y-2">
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a
                        href={link.href}
                        className="text-blue-600 hover:text-blue-700 hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}