import React from 'react';
import { Link } from 'react-router-dom';
import {
  Users,
  Target,
  Award,
  Rocket,
  Heart,
  Zap,
  ArrowRight,
  CheckCircle2,
  Globe,
  Code,
  Database,
  Shield
} from 'lucide-react';
import Logo from '../components/Logo';

export default function About() {
  const values = [
    {
      icon: <Rocket className="h-8 w-8 text-blue-500" />,
      title: 'Innovate',
      description: 'Driving cutting-edge IT solutions and data-driven strategies for digital success',
      image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <Target className="h-8 w-8 text-blue-500" />,
      title: 'Implement',
      description: 'Delivering actionable strategies and solutions for immediate business impact',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <Zap className="h-8 w-8 text-blue-500" />,
      title: 'Improve',
      description: 'Continuously enhancing and optimizing solutions for better results',
      image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=2072'
    }
  ];

  const stats = [
    { 
      number: '100+', 
      label: 'Happy Clients',
      icon: <Users className="h-8 w-8 text-blue-500" />,
      image: 'https://images.unsplash.com/photo-1556761175-b413da4baf72?auto=format&fit=crop&q=80&w=2072'
    },
    { 
      number: '95%', 
      label: 'Client Satisfaction',
      icon: <Heart className="h-8 w-8 text-blue-500" />,
      image: 'https://images.unsplash.com/photo-1552581234-26160f608093?auto=format&fit=crop&q=80&w=2072'
    },
    { 
      number: '15+', 
      label: 'Years Experience',
      icon: <Award className="h-8 w-8 text-blue-500" />,
      image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80&w=2072'
    },
    { 
      number: '24/7', 
      label: 'Support',
      icon: <Shield className="h-8 w-8 text-blue-500" />,
      image: 'https://images.unsplash.com/photo-1549923746-c502d488b3ea?auto=format&fit=crop&q=80&w=2072'
    }
  ];

  const services = [
    {
      icon: <Globe className="h-6 w-6 text-blue-500" />,
      title: 'Web Development',
      description: 'Custom websites and web applications built with cutting-edge technology',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <Code className="h-6 w-6 text-blue-500" />,
      title: 'Mobile Development',
      description: 'Native and cross-platform mobile applications',
      image: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <Database className="h-6 w-6 text-blue-500" />,
      title: 'Cloud Services',
      description: 'Scalable and secure cloud infrastructure solutions',
      image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072'
    },
    {
      icon: <Shield className="h-6 w-6 text-blue-500" />,
      title: 'Digital Marketing',
      description: 'Data-driven strategies for online growth',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072'
    }
  ];

  const features = [
    'End-to-End IT & Digital Marketing Solutions',
    '100+ Satisfied Clients Worldwide',
    'Real-Time Implementation',
    'Customer-Centric Approach',
    'Future-Ready Innovation'
  ];

  return (
    <div className="bg-white">
      {/* Hero Section with Parallax */}
      <div className="relative h-[600px] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.3)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-blue-800/50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
          <div className="text-center w-full">
            <div className="flex justify-center mb-6">
              <Logo className="h-24 w-24" />
            </div>
            <h1 className="text-5xl font-bold text-white mb-6">
              About CyberEdge
            </h1>
            <p className="text-2xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Innovate. Implement. Improve.
            </p>
          </div>
        </div>
      </div>

      {/* Mission Section with Animated Cards */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Mission</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              At Cyberedge Software Solutions, we are driven by three core principles: Innovate, Implement, and Improve. Our mission is to provide cutting-edge IT solutions and data-driven digital marketing strategies that empower businesses to achieve real-time success in an ever-evolving digital landscape.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <div
                key={index}
                className="group relative overflow-hidden rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute inset-0">
                  <img 
                    src={value.image} 
                    alt={value.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                </div>
                <div className="relative p-8 h-full flex flex-col justify-end">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 backdrop-blur-sm rounded-lg">
                      {value.icon}
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold text-white text-center mb-2">{value.title}</h3>
                  <p className="text-gray-200 text-center">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section with Hover Effects */}
      <div className="py-20 bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div 
                key={index} 
                className="group relative overflow-hidden rounded-xl bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all duration-300"
              >
                <div className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity duration-300">
                  <img 
                    src={stat.image} 
                    alt={stat.label}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="relative p-8 text-center">
                  <div className="flex justify-center mb-4">
                    {stat.icon}
                  </div>
                  <div className="text-4xl font-bold text-white mb-2">
                    {stat.number}
                  </div>
                  <div className="text-blue-100">{stat.label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Services Section with Image Cards */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Our Services
            </h2>
            <p className="text-xl text-gray-600">
              Comprehensive digital solutions for your business needs
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="group relative overflow-hidden rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute inset-0">
                  <img 
                    src={service.image} 
                    alt={service.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                </div>
                <div className="relative p-6 h-full flex flex-col justify-end">
                  <div className="flex items-center mb-4">
                    <div className="p-2 bg-white/10 backdrop-blur-sm rounded-lg mr-3">
                      {service.icon}
                    </div>
                    <h3 className="text-xl font-semibold text-white">{service.title}</h3>
                  </div>
                  <p className="text-gray-200">{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Why Choose Us Section with Animated Background */}
      <div className="relative py-20 overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.1)'
          }}
        />
        <div className="absolute inset-0 bg-blue-600/20" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">
              Why Choose CyberEdge?
            </h2>
            <p className="text-xl text-blue-100">
              We don't just provide services – we build success stories
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className="flex items-start space-x-3 bg-white/10 backdrop-blur-sm p-6 rounded-lg hover:bg-white/20 transition-all duration-300"
              >
                <CheckCircle2 className="h-6 w-6 text-blue-400 flex-shrink-0" />
                <span className="text-white">{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section with Parallax */}
      <div className="relative py-20">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.3)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-blue-800/50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-white mb-4">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Partner with us to unlock your business's full potential with technology and strategy that deliver real, measurable results.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-blue-600 transition-colors duration-300"
            >
              Get in Touch
              <ArrowRight className="ml-2 h-6 w-6" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}