import React from 'react';
import { Shield, Zap } from 'lucide-react';

export default function Logo({ className = "h-10 w-10" }) {
  return (
    <div className={`relative ${className}`}>
      <Shield className="text-blue-600 w-full h-full" />
      <Zap 
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white w-1/2 h-1/2" 
      />
    </div>
  );
}