import React from 'react';
import { Link } from 'react-router-dom';
import {
  FileText,
  Briefcase,
  Users,
  MessageSquare,
  Award,
  Building2,
  ArrowRight,
  TrendingUp
} from 'lucide-react';

export default function Overview() {
  const stats = [
    {
      name: 'Blog Posts',
      value: '24',
      href: '/admin/blogs',
      icon: FileText,
      change: '+4.75%',
      changeType: 'positive'
    },
    {
      name: 'Work Portfolio',
      value: '12',
      href: '/admin/work',
      icon: Briefcase,
      change: '+10.18%',
      changeType: 'positive'
    },
    {
      name: 'Active Jobs',
      value: '8',
      href: '/admin/careers',
      icon: Users,
      change: '-3.38%',
      changeType: 'negative'
    },
    {
      name: 'Testimonials',
      value: '32',
      href: '/admin/testimonials',
      icon: MessageSquare,
      change: '+12.5%',
      changeType: 'positive'
    }
  ];

  const quickActions = [
    {
      name: 'Create Blog Post',
      href: '/admin/blogs/new',
      icon: FileText,
      description: 'Write and publish a new blog post'
    },
    {
      name: 'Add Work Item',
      href: '/admin/work/new',
      icon: Briefcase,
      description: 'Add a new portfolio item'
    },
    {
      name: 'Post Job Opening',
      href: '/admin/careers/new',
      icon: Users,
      description: 'Create a new job listing'
    },
    {
      name: 'Add Testimonial',
      href: '/admin/testimonials/new',
      icon: MessageSquare,
      description: 'Add a new client testimonial'
    }
  ];

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard Overview</h1>
        <p className="mt-2 text-gray-600">Welcome to your admin dashboard</p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <Link
            key={stat.name}
            to={stat.href}
            className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 border border-gray-200"
          >
            <div className="flex items-center">
              <div className="p-2 bg-blue-50 rounded-lg">
                <stat.icon className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">{stat.name}</p>
                <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
              </div>
            </div>
            <div className="mt-4 flex items-center">
              <TrendingUp className={`h-4 w-4 ${
                stat.changeType === 'positive' ? 'text-green-500' : 'text-red-500'
              }`} />
              <span className={`ml-2 text-sm ${
                stat.changeType === 'positive' ? 'text-green-600' : 'text-red-600'
              }`}>
                {stat.change}
              </span>
            </div>
          </Link>
        ))}
      </div>

      {/* Quick Actions */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Quick Actions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {quickActions.map((action) => (
            <Link
              key={action.name}
              to={action.href}
              className="group bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 border border-gray-200"
            >
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
                  <action.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="ml-4 text-lg font-medium text-gray-900">{action.name}</h3>
              </div>
              <p className="text-gray-600">{action.description}</p>
              <div className="mt-4 flex items-center text-blue-600 group-hover:text-blue-700">
                Get Started
                <ArrowRight className="ml-2 h-4 w-4" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}