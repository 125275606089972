import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import {
  LayoutDashboard,
  Users,
  FileText,
  Briefcase,
  MessageSquare,
  Award,
  Building2,
  Settings,
  BarChart
} from 'lucide-react';
import Overview from './Overview';
import BlogManager from './BlogManager';
import WorkManager from './WorkManager';
import CareerManager from './CareerManager';
import TestimonialManager from './TestimonialManager';
import CertificationManager from './CertificationManager';
import IndustryManager from './IndustryManager';
import MetricsManager from './MetricsManager';

export default function AdminDashboard() {
  const location = useLocation();
  
  const navigation = [
    { name: 'Overview', href: '/admin', icon: LayoutDashboard },
    { name: 'Blog Posts', href: '/admin/blogs', icon: FileText },
    { name: 'Work Portfolio', href: '/admin/work', icon: Briefcase },
    { name: 'Careers', href: '/admin/careers', icon: Users },
    { name: 'Testimonials', href: '/admin/testimonials', icon: MessageSquare },
    { name: 'Certifications', href: '/admin/certifications', icon: Award },
    { name: 'Industries', href: '/admin/industries', icon: Building2 },
    { name: 'Website Metrics', href: '/admin/metrics', icon: BarChart },
    { name: 'Settings', href: '/admin/settings', icon: Settings }
  ];

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      {/* Sidebar */}
      <aside className="w-64 bg-white border-r border-gray-200">
        <nav className="h-full overflow-y-auto">
          <div className="px-4 py-5">
            <h2 className="text-lg font-semibold text-gray-900">Admin Dashboard</h2>
          </div>
          <div className="px-2">
            {navigation.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md mb-1 ${
                    isActive
                      ? 'bg-blue-50 text-blue-600'
                      : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <item.icon
                    className={`mr-3 h-5 w-5 flex-shrink-0 ${
                      isActive ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'
                    }`}
                  />
                  {item.name}
                </Link>
              );
            })}
          </div>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="flex-1 overflow-y-auto bg-gray-50">
        <Routes>
          <Route index element={<Overview />} />
          <Route path="blogs/*" element={<BlogManager />} />
          <Route path="work/*" element={<WorkManager />} />
          <Route path="careers/*" element={<CareerManager />} />
          <Route path="testimonials/*" element={<TestimonialManager />} />
          <Route path="certifications/*" element={<CertificationManager />} />
          <Route path="industries/*" element={<IndustryManager />} />
          <Route path="metrics/*" element={<MetricsManager />} />
        </Routes>
      </main>
    </div>
  );
}