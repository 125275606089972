import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Globe,
  Search,
  Smartphone,
  Code,
  Database,
  Shield,
  ArrowRight,
  CheckCircle2,
  Server,
  ShoppingBag,
  Settings,
  Zap,
  Layout,
  Users,
  Mail,
  MessageSquare,
  BarChart,
  Lock,
  Cloud,
  Repeat,
  Bot,
  ChevronDown
} from 'lucide-react';

export default function Solutions() {
  const [expandedSolution, setExpandedSolution] = useState<number | null>(null);

  const solutions = [
    {
      icon: <Globe className="h-12 w-12 text-blue-500" />,
      title: 'Web Development',
      description: 'Build, Scale, and Transform Your Digital Presence',
      features: [
        'Custom Website Development',
        'Responsive Web Design',
        'E-Commerce Development',
        'CMS Development',
        'Enterprise Web Solutions',
        'Full-Stack Development',
        'API Development & Integration',
        'Website Maintenance & Support'
      ],
      benefits: [
        'User-Centric & SEO-Friendly Designs',
        'Scalable & Future-Proof',
        'Lightning-Fast Performance',
        'Secure & Reliable',
        'Cloud-Ready Development'
      ],
      technologies: ['React', 'Angular', 'Vue.js', 'Node.js', 'Python', 'PHP', '.NET'],
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'Projects Delivered', value: '500+' },
        { label: 'Client Satisfaction', value: '98%' },
        { label: 'Performance Increase', value: '40%' }
      ]
    },
    {
      icon: <Smartphone className="h-12 w-12 text-blue-500" />,
      title: 'Mobile App Development',
      description: 'Creating High-Performance, Scalable, and User-Centric Apps',
      features: [
        'iOS App Development',
        'Android App Development',
        'Cross-Platform App Development',
        'Progressive Web Apps (PWA)',
        'Enterprise Mobile Solutions',
        'UI/UX Design for Mobile Apps',
        'Mobile App Testing & QA',
        'App Maintenance & Support'
      ],
      benefits: [
        'End-to-End Development',
        'User-Centric Approach',
        'Scalable & Secure Apps',
        'AI & IoT Integration',
        'Cloud-Based Solutions',
        'Agile Development Methodology'
      ],
      technologies: ['Swift', 'Kotlin', 'React Native', 'Flutter', 'Xamarin'],
      image: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'App Downloads', value: '1M+' },
        { label: 'App Store Rating', value: '4.8/5' },
        { label: 'User Retention', value: '85%' }
      ]
    },
    {
      icon: <Search className="h-12 w-12 text-blue-500" />,
      title: 'Digital Marketing',
      description: 'Transforming Brands with Data-Driven Strategies',
      features: [
        'Search Engine Optimization (SEO)',
        'Pay-Per-Click (PPC) Advertising',
        'Social Media Marketing (SMM)',
        'Content Marketing',
        'Email Marketing',
        'Conversion Rate Optimization (CRO)',
        'Influencer & Affiliate Marketing',
        'Online Reputation Management (ORM)'
      ],
      benefits: [
        'Data-Driven Strategies',
        'Full-Funnel Marketing Approach',
        'ROI-Focused Campaigns',
        'Certified Digital Experts',
        'Customized Solutions'
      ],
      certifications: ['Google Ads', 'Facebook Blueprint', 'HubSpot Inbound'],
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'Traffic Increase', value: '200%' },
        { label: 'Lead Generation', value: '150%' },
        { label: 'ROI', value: '300%' }
      ]
    },
    {
      icon: <Cloud className="h-12 w-12 text-blue-500" />,
      title: 'Cloud Services',
      description: 'Scalable, Secure & Future-Ready Solutions',
      features: [
        'Cloud Infrastructure & Migration',
        'Cloud App Development',
        'Managed Cloud Services',
        'Cloud Security & Compliance',
        'Multi-Cloud & Hybrid Cloud Solutions',
        'Serverless Computing & DevOps',
        'Cloud Backup & Disaster Recovery',
        'Cloud AI & Big Data Solutions'
      ],
      benefits: [
        'Scalability & Cost Efficiency',
        'Enterprise-Grade Security',
        'End-to-End Cloud Solutions',
        'Seamless Integration',
        'Optimized Performance',
        'Global Accessibility'
      ],
      platforms: ['AWS', 'Azure', 'Google Cloud'],
      image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'Cost Reduction', value: '40%' },
        { label: 'Uptime', value: '99.9%' },
        { label: 'Performance', value: '60%' }
      ]
    },
    {
      icon: <ShoppingBag className="h-12 w-12 text-blue-500" />,
      title: 'E-Commerce Solutions',
      description: 'Build, Scale, and Succeed in Online Retail',
      features: [
        'Custom E-Commerce Development',
        'E-Commerce Mobile Applications',
        'Marketplace Development',
        'Seamless Payment Integration',
        'Headless E-Commerce Solutions',
        'Personalized Shopping & AI Integration',
        'Inventory & Order Management Systems',
        'Performance Optimization & Security'
      ],
      benefits: [
        'Scalable & Future-Ready',
        'Conversion-Focused UX/UI',
        'Multi-Device Compatibility',
        'Cloud-Powered Efficiency',
        'Ongoing Support & Enhancements'
      ],
      platforms: ['Shopify', 'WooCommerce', 'Magento'],
      image: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'Sales Growth', value: '200%' },
        { label: 'Conversion Rate', value: '4.5%' },
        { label: 'Cart Recovery', value: '35%' }
      ]
    },
    {
      icon: <Code className="h-12 w-12 text-blue-500" />,
      title: 'Custom Software Development',
      description: 'Tailored Solutions for Business Excellence',
      features: [
        'Enterprise Software Development',
        'Process Automation & Workflow Optimization',
        'AI & Data-Driven Applications',
        'Cloud-Based Software Development',
        'Custom CRM & ERP Development',
        'SaaS Application Development',
        'API Development & Third-Party Integrations',
        'Legacy Software Modernization'
      ],
      benefits: [
        'Fully Tailored & Scalable',
        'Cutting-Edge Technology',
        'User-Centric & Performance-Optimized',
        'End-to-End Development',
        'Security & Compliance'
      ],
      technologies: ['AI/ML', 'Cloud Computing', 'Automation'],
      image: 'https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?auto=format&fit=crop&q=80&w=2072',
      stats: [
        { label: 'Process Efficiency', value: '75%' },
        { label: 'Time Saved', value: '60%' },
        { label: 'Cost Savings', value: '45%' }
      ]
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section with Parallax */}
      <div className="relative h-[600px] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.3)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-blue-800/50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
          <div className="text-center w-full">
            <h1 className="text-5xl font-bold text-white mb-6">
              Enterprise Solutions for Digital Success
            </h1>
            <p className="text-2xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Discover our comprehensive range of digital solutions designed to transform your business and drive growth in the digital age.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-blue-600 transition-colors duration-300"
            >
              Schedule a Consultation
              <ArrowRight className="ml-2 h-6 w-6" />
            </Link>
          </div>
        </div>
      </div>

      {/* Solutions Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        {solutions.map((solution, index) => (
          <div 
            key={index} 
            className={`mb-20 last:mb-0 ${
              expandedSolution === index ? 'bg-gray-50 rounded-2xl p-8' : ''
            }`}
          >
            <div className="relative overflow-hidden rounded-xl mb-12">
              <img 
                src={solution.image} 
                alt={solution.title}
                className="w-full h-[400px] object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <div className="flex items-center mb-4">
                  <div className="p-4 bg-white/10 backdrop-blur-sm rounded-lg mr-4">
                    {solution.icon}
                  </div>
                  <h2 className="text-3xl font-bold text-white">
                    {solution.title}
                  </h2>
                </div>
                <p className="text-xl text-gray-200 max-w-3xl">
                  {solution.description}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
              {solution.stats.map((stat, statIndex) => (
                <div 
                  key={statIndex}
                  className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 text-center"
                >
                  <div className="text-3xl font-bold text-blue-600 mb-2">
                    {stat.value}
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">Key Features</h3>
                <div className="space-y-4">
                  {solution.features.map((feature, featureIndex) => (
                    <div key={featureIndex} className="flex items-start space-x-3">
                      <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">Benefits</h3>
                <div className="space-y-4">
                  {solution.benefits.map((benefit, benefitIndex) => (
                    <div key={benefitIndex} className="flex items-start space-x-3">
                      <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{benefit}</span>
                    </div>
                  ))}
                </div>
                {(solution.technologies || solution.platforms || solution.certifications) && (
                  <div className="mt-8 pt-6 border-t border-gray-100">
                    <h4 className="text-lg font-medium text-gray-900 mb-4">
                      {solution.technologies ? 'Technologies' : 
                       solution.platforms ? 'Platforms' : 
                       'Certifications'}
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {(solution.technologies || solution.platforms || solution.certifications)?.map((item, itemIndex) => (
                        <span
                          key={itemIndex}
                          className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
              >
                Learn More About {solution.title}
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* CTA Section with Parallax */}
      <div className="relative py-20">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-fixed"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2072)',
            filter: 'brightness(0.3)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-blue-800/50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-white mb-4">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Let's work together to create innovative solutions that drive your success
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/contact"
                className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-blue-600 transition-colors duration-300"
              >
                Get in Touch
                <ArrowRight className="ml-2 h-6 w-6" />
              </Link>
              <Link
                to="/work"
                className="inline-flex items-center px-8 py-4 border-2 border-white bg-white text-lg font-medium rounded-md text-blue-600 hover:bg-transparent hover:text-white transition-colors duration-300"
              >
                View Our Work
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}