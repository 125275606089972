import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  ArrowRight, 
  Globe, 
  BarChart, 
  Smartphone, 
  ShoppingBag, 
  Building2,
  Briefcase,
  ExternalLink,
  Filter,
  CheckCircle2
} from 'lucide-react';

export default function Work() {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [selectedIndustry, setSelectedIndustry] = useState<string>('all');

  const categories = [
    { id: 'all', name: 'All' },
    { id: 'web', name: 'Web Development' },
    { id: 'mobile', name: 'Mobile Apps' },
    { id: 'ecommerce', name: 'E-commerce' },
    { id: 'digital-marketing', name: 'Digital Marketing' },
  ];

  const industries = [
    { id: 'all', name: 'All Industries' },
    { id: 'healthcare', name: 'Healthcare' },
    { id: 'retail', name: 'Retail' },
    { id: 'education', name: 'Education' },
    { id: 'finance', name: 'Finance' },
    { id: 'real-estate', name: 'Real Estate' },
  ];

  const projects = [
    {
      title: 'HealthTech Solutions Portal',
      description: 'A comprehensive healthcare management system with patient portal, appointment scheduling, and electronic health records.',
      category: 'web',
      industry: 'healthcare',
      image: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&q=80&w=1000',
      results: [
        'Reduced patient wait times by 45%',
        'Increased appointment bookings by 60%',
        '98% patient satisfaction rate'
      ],
      technologies: ['React', 'Node.js', 'PostgreSQL', 'AWS'],
      link: '#'
    },
    {
      title: 'EduLearn Platform',
      description: 'An interactive e-learning platform with live classes, course management, and student progress tracking.',
      category: 'web',
      industry: 'education',
      image: 'https://images.unsplash.com/photo-1501504905252-473c47e087f8?auto=format&fit=crop&q=80&w=1000',
      results: [
        'Over 10,000 active students',
        '95% course completion rate',
        '40% increase in student engagement'
      ],
      technologies: ['Vue.js', 'Django', 'MongoDB', 'GCP'],
      link: '#'
    },
    {
      title: 'SmartShop E-commerce',
      description: 'A feature-rich e-commerce platform with inventory management, analytics, and mobile app.',
      category: 'ecommerce',
      industry: 'retail',
      image: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?auto=format&fit=crop&q=80&w=1000',
      results: [
        '200% increase in online sales',
        'Reduced cart abandonment by 35%',
        'Mobile app downloads: 50,000+'
      ],
      technologies: ['Next.js', 'Shopify', 'React Native', 'Firebase'],
      link: '#'
    },
    {
      title: 'FinTech Mobile App',
      description: 'A secure mobile banking application with real-time transactions, investment tracking, and budgeting tools.',
      category: 'mobile',
      industry: 'finance',
      image: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?auto=format&fit=crop&q=80&w=1000',
      results: [
        '1M+ app downloads',
        '4.8/5 app store rating',
        'Processing $10M+ daily transactions'
      ],
      technologies: ['React Native', 'Node.js', 'MongoDB', 'AWS'],
      link: '#'
    },
    {
      title: 'RealEstate360',
      description: 'A comprehensive real estate platform with virtual tours, property management, and agent portal.',
      category: 'web',
      industry: 'real-estate',
      image: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&q=80&w=1000',
      results: [
        'Increased property viewings by 80%',
        '50% faster property listing process',
        '30% increase in agent efficiency'
      ],
      technologies: ['React', 'Express', 'PostgreSQL', 'Azure'],
      link: '#'
    },
    {
      title: 'RetailAnalytics Dashboard',
      description: 'Advanced analytics platform for retail chains with AI-powered insights and forecasting.',
      category: 'digital-marketing',
      industry: 'retail',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=1000',
      results: [
        'Improved inventory accuracy by 40%',
        '25% reduction in stockouts',
        'ROI achieved in 6 months'
      ],
      technologies: ['Angular', 'Python', 'TensorFlow', 'GCP'],
      link: '#'
    }
  ];

  const filteredProjects = projects.filter(project => {
    const matchesCategory = selectedCategory === 'all' || project.category === selectedCategory;
    const matchesIndustry = selectedIndustry === 'all' || project.industry === selectedIndustry;
    return matchesCategory && matchesIndustry;
  });

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-white mb-6">
              Our Work
            </h1>
            <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Explore our portfolio of successful digital transformations and innovative solutions that have helped businesses thrive.
            </p>
          </div>
        </div>
      </div>

      {/* Filters Section */}
      <div className="bg-white py-8 border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <Filter className="h-5 w-5 text-gray-400" />
              <div className="space-x-2">
                {categories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className={`px-4 py-2 rounded-full text-sm font-medium ${
                      selectedCategory === category.id
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            </div>
            <select
              value={selectedIndustry}
              onChange={(e) => setSelectedIndustry(e.target.value)}
              className="block w-full md:w-auto px-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              {industries.map((industry) => (
                <option key={industry.id} value={industry.id}>
                  {industry.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Projects Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProjects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-shadow overflow-hidden"
            >
              <div className="aspect-w-16 aspect-h-9 relative">
                <img
                  src={project.image}
                  alt={project.title}
                  className="object-cover w-full h-48"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {project.title}
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {project.technologies.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="px-2 py-1 bg-white/20 backdrop-blur-sm rounded-full text-white text-xs"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="p-6">
                <p className="text-gray-600 mb-4">
                  {project.description}
                </p>
                <div className="space-y-2 mb-6">
                  {project.results.map((result, resultIndex) => (
                    <div key={resultIndex} className="flex items-start space-x-2">
                      <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{result}</span>
                    </div>
                  ))}
                </div>
                <a
                  href={project.link}
                  className="inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  View Case Study
                  <ExternalLink className="ml-2 h-4 w-4" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Ready to Start Your Project?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Let's work together to create something amazing for your business
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Get in Touch
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}