import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, ArrowRight, HelpCircle } from 'lucide-react';

export default function FAQ() {
  const [openSection, setOpenSection] = useState<string | null>(null);

  const faqs = {
    general: [
      {
        question: 'What services does CyberEdge offer?',
        answer: 'CyberEdge offers a comprehensive range of digital solutions including web development, mobile app development, digital marketing, cloud services, and custom software development.'
      },
      {
        question: 'How do I get started with CyberEdge?',
        answer: "Getting started is easy! Simply contact our team through the contact form or schedule a consultation. We'll discuss your needs and create a tailored solution for your business."
      },
      {
        question: 'What industries do you serve?',
        answer: 'We serve a wide range of industries including healthcare, education, finance, retail, real estate, and more. Our solutions are customized to meet industry-specific requirements.'
      }
    ],
    technical: [
      {
        question: 'What technologies do you use?',
        answer: 'We use the latest technologies including React, Node.js, Python, AWS, Azure, and more. Our tech stack is chosen based on project requirements and best practices.'
      },
      {
        question: 'How do you ensure project security?',
        answer: 'We implement industry-standard security measures, including encrypted communications, secure authentication, regular security audits, and compliance with data protection regulations.'
      },
      {
        question: 'Do you provide ongoing support?',
        answer: 'Yes, we offer comprehensive support and maintenance packages to ensure your solutions continue to perform optimally after launch.'
      }
    ],
    billing: [
      {
        question: 'What are your payment terms?',
        answer: 'We offer flexible payment terms based on project scope. Typically, this includes a project initiation fee and milestone-based payments throughout development.'
      },
      {
        question: 'Do you offer subscription-based services?',
        answer: 'Yes, we offer subscription-based services for ongoing support, maintenance, and digital marketing services. Contact us for detailed pricing.'
      },
      {
        question: "What's included in your pricing?",
        answer: 'Our pricing includes project planning, development, testing, deployment, and initial support. Additional services like extended support or maintenance are priced separately.'
      }
    ]
  };

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <HelpCircle className="h-16 w-16 text-white mx-auto mb-6" />
            <h1 className="text-4xl font-bold text-white mb-6">
              Frequently Asked Questions
            </h1>
            <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Find answers to common questions about our services and solutions
            </p>
          </div>
        </div>
      </div>

      {/* FAQ Sections */}
      <div className="py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          {Object.entries(faqs).map(([category, questions]) => (
            <div key={category} className="mb-8">
              <button
                onClick={() => toggleSection(category)}
                className="w-full flex items-center justify-between p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow border border-gray-200"
              >
                <h2 className="text-2xl font-bold text-gray-900 capitalize">
                  {category} Questions
                </h2>
                {openSection === category ? (
                  <ChevronUp className="h-6 w-6 text-blue-500" />
                ) : (
                  <ChevronDown className="h-6 w-6 text-blue-500" />
                )}
              </button>

              {openSection === category && (
                <div className="mt-4 space-y-4">
                  {questions.map((faq, index) => (
                    <div
                      key={index}
                      className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
                    >
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Still Have Questions?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Contact our support team for personalized assistance
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Support
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link
                to="/support"
                className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Visit Help Center
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}